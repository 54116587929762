<template>
  <div>
    <v-menu v-if="!readonly" bottom offset-y>
      <template #activator="{ on: menu }">
        <v-tooltip left color="primary">
          <template #activator="{ on: tooltip }">
            <v-btn icon v-on="{ ...tooltip, ...menu }">
              <v-icon
                :color="
                  section === 1 && $vuetify.theme.dark === false
                    ? 'white'
                    : 'secondary'
                "
              >
                fal fa-plus-circle
              </v-icon>
            </v-btn>
          </template>
          <span>Add Publication</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="type in valueListsStore.publicationTypeList"
          :key="type"
          @click="newEntry(type)"
        >
          <v-list-item-title>{{ type }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="1200"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card height="700" flat>
        <v-card-title class="text-h5 secondary--text">
          Publication Details
          <v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()">
            <v-icon small left>
              fal fa-times </v-icon
            >Cancel
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="pb-0 pt-4">
          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tabs-slider light />

            <v-tab :key="1" :href="'#tab-1'">
              Publication Information
            </v-tab>

            <v-tab :key="2" :href="'#tab-2'">
              T-2 Researchers
            </v-tab>

            <v-tab :key="3" :href="'#tab-3'">
              T-2 Trainees
            </v-tab>

            <v-tab :key="4" :href="'#tab-4'">
              Non T-2 Participants
            </v-tab>
          </v-tabs>
        </v-card-subtitle>
        <v-card-text class="py-0">
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="saveEntry()"
          >
            <v-tabs-items v-model="tab">
              <!--General Information-->
              <v-tab-item :key="1" value="tab-1">
                <v-container class="py-0" fluid>
                  <v-card flat>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <span class="subtitle-1 font-weight-bold black--text"
                          >You will not be able to submit the record without
                          completing required fields. Fields that are
                          non-required for DOP purposes are required for PI's
                          annual RPPR thus completion is recommended. Note that
                          based on selections you make (e.g., peer-reviewed
                          versus non-peer-reviewed) different fields will be
                          presented to you based on information that is needed
                          for RPPR annual reporting.</span
                        >
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <span class="caption error--text"
                          >* Required Fields</span
                        >
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Publication Type-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          v-model="type"
                          :items="valueListsStore.publicationTypeList"
                          label="Publication Type *"
                          placeholder=" "
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Author of Record-->
                      <v-col cols="12" sm="12" md="6">
                        <v-autocomplete
                          v-model="id_user"
                          :items="participantsStore.researchersValueList"
                          item-text="name_reverse"
                          item-value="id"
                          label="Author Of Record *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Status-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          v-model="flag_status"
                          :items="valueListsStore.publicationStatusValueList"
                          item-text="name"
                          item-value="status"
                          label="Status *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="flag_status === 0 ? [] : rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Support Acknowledgment-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          v-model="support_acknowledgment"
                          :items="valueListsStore.yesNo"
                          item-text="value"
                          item-value="value"
                          label="EPSCoR T-2 Support Acknowledgment *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                          @input="setFedAck(support_acknowledgment)"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Support Acknowledgment Description-->
                      <v-col
                        v-if="support_acknowledgment === 'No'"
                        cols="12"
                        sm="12"
                      >
                        <v-textarea
                          v-model="support_ack_description"
                          label="Provide brief justification for including this publication *"
                          placeholder=" "
                          rows="1"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Federal Support Acknowledgment-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          v-model="acknowledgment_fed_support"
                          :items="valueListsStore.yesNo"
                          item-text="value"
                          item-value="value"
                          label="Federal Support Acknowledgment"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Related T2-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          v-model="related_t2"
                          :items="valueListsStore.yesNo"
                          item-text="value"
                          item-value="value"
                          label="Was publication based on work supported by T-2 award? *"
                          placeholder=" "
                          required
                          :rules="rules.not_empty"
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Related T2 Description-->
                      <v-col v-if="related_t2 === 'No'" cols="12" sm="12">
                        <v-textarea
                          v-model="description"
                          label="Provide brief justification for including this publication *"
                          placeholder=" "
                          rows="1"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Peer Reviewed-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          v-model="peer_review"
                          :items="valueListsStore.yesNo"
                          item-text="value"
                          item-value="value"
                          label="Peer Reviewed *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Related T2 Description-->
                      <v-col
                        v-if="
                          (peer_review === 'No' &&
                            type === 'Journal Article Print') ||
                            (peer_review === 'No' &&
                              type === 'Journal Article Electronic Only')
                        "
                        cols="12"
                        sm="12"
                      >
                        <v-textarea
                          v-model="peer_review_description"
                          label="Description of publication (e.g., review article, op ed)"
                          placeholder=" "
                          rows="1"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Open Access-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          v-model="open_access"
                          :items="valueListsStore.yesNoPartial"
                          item-text="value"
                          item-value="value"
                          label="Open Access"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Open Access Other-->
                      <v-col
                        v-if="open_access === 'Partial'"
                        cols="12"
                        sm="12"
                        md="9"
                      >
                        <v-text-field
                          v-model="open_access_other"
                          label="Specify Partial Type *"
                          placeholder=" "
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Identification Type-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          v-model="identifier"
                          :items="valueListsStore.publicationIdentificationType"
                          label="Publication Identifier"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--DOI-->
                      <v-col
                        v-if="identifier === 'DOI'"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="doi"
                          label="DOI code"
                          placeholder=" "
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--DOI Lookup Button-->
                      <v-col
                        v-if="identifier === 'DOI'"
                        cols="12"
                        sm="12"
                        md="3"
                      >
                        <v-tooltip bottom light>
                          <template #activator="{ on }">
                            <v-btn
                              color="primary"
                              block
                              v-on="on"
                              @click="lookupDOI(doi)"
                            >
                              <v-icon small left>
                                far fa-search </v-icon
                              >Lookup
                            </v-btn>
                          </template>
                          <span
                            >Lookup DOI and auto populate the fields below</span
                          >
                        </v-tooltip>
                      </v-col>

                      <!-- Other Identification -->
                      <v-col
                        v-if="identifier && identifier !== 'DOI'"
                        cols="12"
                        sm="12"
                        md="9"
                      >
                        <v-text-field
                          v-model="other_identifier"
                          :label="
                            identifier === 'ISBN'
                              ? 'ISBN code'
                              : identifier === 'ISSN'
                              ? 'ISSN code'
                              : 'Other code'
                          "
                          placeholder=" "
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <!--JOURNAL ARTICLE PRINT-->
                    <div v-if="type === 'Journal Article Print'">
                      <v-row dense>
                        <!--Title-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="title"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Title *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Journal Title-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="journal"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Journal (Full Title) *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--URL-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="url"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="URL"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Authors-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="authors"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Author(s) (e.g., Doe, Jane, Smith, Stan, and Jones, George) in the order they appear on the publication."
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row v-if="identifier && identifier === 'DOI'" dense>
                        <!--Acknowledgements-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="acknowledgments"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Acknowledgements"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Published Date Month-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="month"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            :items="valueListsStore.monthAbbreviations"
                            item-text="value"
                            item-value="value"
                            label="Published Date - Month *"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Published Date Year-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="year"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            :items="valueListsStore.projectYearList"
                            item-text="value"
                            item-value="value"
                            label="Published Date - Year *"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Estimated Publication Date Month-->
                        <v-col
                          v-if="flag_status !== 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="estimated_month"
                            :items="valueListsStore.monthAbbreviations"
                            item-text="value"
                            item-value="value"
                            label="Estimated Publication Date - Month"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Estimated Publication Date Year-->
                        <v-col
                          v-if="flag_status !== 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="estimated_year"
                            :items="valueListsStore.projectYearList"
                            item-text="value"
                            item-value="value"
                            label="Estimated Publication Date - Year"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Volume-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="volume"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Volume"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Issue-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="issue"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Issue"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Page-->
                        <v-col v-if="flag_status === 1" cols="4" sm="2">
                          <v-text-field
                            v-model="page"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Page (#-#) *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>
                    </div>

                    <!--ELECTRONIC JOURNAL ARTICLE-->
                    <div v-if="type === 'Journal Article Electronic Only'">
                      <v-row dense>
                        <!--Title-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="title"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Title *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Journal Title-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="journal"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Journal (Full Title) *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row v-if="flag_status === 1" dense>
                        <!--URL-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="url"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="URL - required if there is no DOI *"
                            placeholder=" "
                            required
                            :rules="doi ? [] : rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Authors-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="authors"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Author(s) (e.g., Doe, Jane, Smith, Stan, and Jones, George) in the order they appear on the publication"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="identifier && identifier === 'DOI'" dense>
                        <!--Acknowledgements-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="acknowledgments"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Acknowledgements"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Cited Month-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="citedMonth"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            :items="valueListsStore.monthAbbreviations"
                            item-text="value"
                            item-value="value"
                            label="Cited Online Date - Month *"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Cited Year-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="citedYear"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            :items="valueListsStore.projectYearList"
                            item-text="value"
                            item-value="value"
                            label="Cited Online Date - Year *"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Estimated Publication Date Month-->
                        <v-col
                          v-if="flag_status !== 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="estimated_month"
                            :items="valueListsStore.monthAbbreviations"
                            item-text="value"
                            item-value="value"
                            label="Estimated Publication Date - Month"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Estimated Publication Date Year-->
                        <v-col
                          v-if="flag_status !== 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="estimated_year"
                            :items="valueListsStore.projectYearList"
                            item-text="value"
                            item-value="value"
                            label="Estimated Publication Date - Year"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Volume-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="volume"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Volume"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Issue-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="issue"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Issue"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>
                    </div>

                    <!--CONFERENCE PROCEEDING-->
                    <div v-if="type === 'Conference Proceeding'">
                      <v-row dense>
                        <!--Title-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="title"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Title *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Journal, Book, URL-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="journal"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Journal, Book or Website (URL) *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Authors-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="authors"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Author(s) (e.g., Doe, Jane, Smith, Stan, and Jones, George) in the order they appear on the publication"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="identifier && identifier === 'DOI'" dense>
                        <!--Acknowledgements-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="acknowledgments"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Acknowledgements"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Conference Name-->
                        <v-col cols="12" sm="12" md="8">
                          <v-text-field
                            v-model="conferenceName"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Conference Name *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Conference Location-->
                        <v-col cols="12" sm="12" md="4">
                          <v-autocomplete
                            v-model="conferenceLocation"
                            :items="valueListsStore.publicationLocationList"
                            item-text="value"
                            item-value="value"
                            label="Conference Location"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--City State Country-->
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="cityStateCountry"
                            label="City, State, Country"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Published Date Month-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="month"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            :items="valueListsStore.monthAbbreviations"
                            item-text="value"
                            item-value="value"
                            label="Published Date - Month *"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Published Date Year-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="year"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            :items="valueListsStore.projectYearList"
                            item-text="value"
                            item-value="value"
                            label="Published Date - Year *"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Estimated Publication Date Month-->
                        <v-col
                          v-if="flag_status && flag_status !== 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="estimated_month"
                            :items="valueListsStore.monthAbbreviations"
                            item-text="value"
                            item-value="value"
                            label="Estimated Publication Date - Month"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Estimated Publication Date Year-->
                        <v-col
                          v-if="flag_status && flag_status !== 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="estimated_year"
                            :items="valueListsStore.projectYearList"
                            item-text="value"
                            item-value="value"
                            label="Estimated Publication Date - Year"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Volume-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="volume"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Volume"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Issue-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="issue"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Issue"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Page-->
                        <v-col v-if="flag_status === 1" cols="4" sm="2">
                          <v-text-field
                            v-model="page"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Page (#-#)"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>
                    </div>

                    <!--BOOK CHAPTER-->
                    <div v-if="type === 'Book Chapter'">
                      <v-row dense>
                        <!--Title-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="title"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Title *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Book Title-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="book_title"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Book Title *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Editors-->
                        <v-col cols="12" sm="9">
                          <v-text-field
                            v-model="editors"
                            label="Editors"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Edition-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="book_edition"
                            label="Edition"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Authors-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="authors"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Author(s) (e.g., Doe, Jane, Smith, Stan, and Jones, George) in the order they appear on the publication"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Publisher-->
                        <v-col cols="12" sm="12" md="5">
                          <v-text-field
                            v-model="publisher"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Publisher *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Publication Location-->
                        <v-col cols="12" sm="12" md="5">
                          <v-text-field
                            v-model="publisher_location"
                            label="Publisher Location"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--E Location ID-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="eLocationID"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="First Page Number or E-location ID"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="identifier && identifier === 'DOI'" dense>
                        <!--Acknowledgements-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="acknowledgments"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Acknowledgements"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Published Date Year-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="year"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            :items="valueListsStore.projectYearList"
                            item-text="value"
                            item-value="value"
                            label="Published Date - Year *"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Estimated Publication Date Year-->
                        <v-col
                          v-if="flag_status !== 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="estimated_year"
                            :items="valueListsStore.projectYearList"
                            item-text="value"
                            item-value="value"
                            label="Estimated Publication Date - Year"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Volume-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="volume"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Volume"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Page-->
                        <v-col v-if="flag_status === 1" cols="4" sm="2">
                          <v-text-field
                            v-model="page"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Page (#-#)"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>
                    </div>

                    <!--BOOK-->
                    <div v-if="type === 'Book'">
                      <v-row dense>
                        <!--Title-->
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="title"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Title *"
                            placeholder=" "
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Editors-->
                        <v-col cols="12" sm="9">
                          <v-text-field
                            v-model="editors"
                            label="Editors"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Edition-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="book_edition"
                            label="Edition"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Authors-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="authors"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Author(s) (e.g., Doe, Jane, Smith, Stan, and Jones, George) in the order they appear on the publication"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Publisher-->
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            v-model="publisher"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Publisher"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Publication Location-->
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            v-model="publisher_location"
                            label="Publisher Location"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="identifier && identifier === 'DOI'" dense>
                        <!--Acknowledgements-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="acknowledgments"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Acknowledgements"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <!--Published Date Year-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="year"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            :items="valueListsStore.projectYearList"
                            item-text="value"
                            item-value="value"
                            label="Published Date - Year *"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            required
                            :rules="rules.not_empty"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Estimated Publication Date Year-->
                        <v-col
                          v-if="flag_status !== 1"
                          cols="12"
                          sm="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="estimated_year"
                            :items="valueListsStore.projectYearList"
                            item-text="value"
                            item-value="value"
                            label="Estimated Publication Date - Year"
                            placeholder=" "
                            :clearable="readonly !== true"
                            clear-icon="fal fa-times-circle"
                            :readonly="readonly"
                          />
                        </v-col>

                        <!--Volume-->
                        <v-col
                          v-if="flag_status === 1"
                          cols="12"
                          sm="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="volume"
                            :background-color="
                              $vuetify.theme.dark === false
                                ? 'light-blue lighten-5'
                                : 'grey darken-3'
                            "
                            label="Volume"
                            placeholder=" "
                            :readonly="readonly"
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-container>
              </v-tab-item>

              <!--T-2 Researchers-->
              <v-tab-item :key="2" value="tab-2">
                <participants-selector
                  :user-type="1"
                  :section="2"
                  :role-selector="0"
                  :readonly="readonly"
                />
              </v-tab-item>

              <!--T-2 Trainees-->
              <v-tab-item :key="3" value="tab-3">
                <participants-selector
                  :user-type="2"
                  :section="2"
                  :role-selector="0"
                  :readonly="readonly"
                />
              </v-tab-item>

              <!--Non T-2 Participants-->
              <v-tab-item :key="4" value="tab-4">
                <participants-selector
                  :user-type="3"
                  :section="2"
                  :role-selector="0"
                  :readonly="readonly"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-card-text>

        <v-card-actions class="mx-4 pb-4">
          <v-row dense>
            <v-col
              v-if="tab === 'tab-2' || tab === 'tab-3' || tab === 'tab-4'"
              cols="3"
              sm="3"
            >
              <v-btn
                block
                rounded
                color="red"
                dark
                outlined
                @click="previousTab()"
              >
                <v-icon small left>
                  fal fa-arrow-left </v-icon
                >Back
              </v-btn>
            </v-col>

            <v-spacer />

            <v-col v-if="tab === 'tab-4' && !readonly" cols="3" sm="3">
              <v-btn block rounded color="primary" @click="saveEntry()">
                <v-icon small left>
                  fal fa-check </v-icon
                >Submit
              </v-btn>
            </v-col>

            <v-col
              v-if="tab === 'tab-1' || tab === 'tab-2' || tab === 'tab-3'"
              cols="3"
              sm="3"
            >
              <v-btn
                block
                rounded
                class="green darken-2"
                dark
                @click="nextTab()"
              >
                Next
                <v-icon small right>
                  fal fa-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import { createHelpers } from "vuex-map-fields";
import { doiURL } from "@/env";
import ParticipantsSelector from "@/components/ParticipantsSelector";

const { mapFields: mapEntryFields } = createHelpers({
  getterType: "publicationsStore/getEntryField",
  mutationType: "publicationsStore/updateEntryField",
});

export default {
  name: "Publication",
  components: {
    ParticipantsSelector,
  },
  mixins: [mixin],
  props: {
    section: Number,
    readonly: Boolean,
  },
  data() {
    return {
      rules: {
        not_empty: [(v) => !!v || ""],
      },
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      projectsStore: (state) => state.projectsStore,
      participantsStore: (state) => state.participantsStore,
      publicationsStore: (state) => state.publicationsStore,
      valueListsStore: (state) => state.valueListsStore,
      jurisdictionsStore: (state) => state.jurisdictionsStore,
    }),
    ...mapEntryFields([
      "show",
      "valid",
      "mode",
      "view",
      "id",

      "tab",

      /*-----ADD ENTRY FIELDS BELOW-----*/
      "id_user",
      "id_project",
      "id_jurisdiction_funding_application",
      "related_t2",
      "type",
      "title",
      "description",
      "peer_review",
      "peer_review_description",
      "support_acknowledgment",
      "support_ack_description",
      "acknowledgment_fed_support",
      "open_access",
      "open_access_other",
      "identifier",
      "doi",
      "other_identifier",
      "journal",
      "url",
      "book_title",
      "book_edition",
      "authors",
      "nont2_archive",
      "month",
      "estimated_month",
      "year",
      "estimated_year",
      "volume",
      "issue",
      "page",
      "citedMonth",
      "citedYear",
      "ePubYear",
      "eLocationID",
      "conferenceName",
      "conferenceLocation",
      "editors",
      "publisher",
      "publisher_location",
      "acknowledgments",
      "cityStateCountry",
      "flag_status",
      "flag_show",

      "participants",
      "participants_selected",
      "non_t2_participants",
      "non_t2",
    ]),
  },

  methods: {
    async newEntry(type) {
      this.$store.dispatch("publicationsStore/resetEntry");

      this.$store.dispatch("participantsStore/resetEntry");

      await this.$_loadValueLists(11);

      const activeProject = this.$store.getters["usersStore/getActiveProject"];
      const activeUser = this.$store.getters["usersStore/getActiveUser"];
      const activeUserType = this.$store.getters[
        "usersStore/getActiveUserType"
      ];

      const data = {
        id: activeUser.id,
        role: "",
        name_reverse: activeUser.name_reverse,
        type: activeUserType === 1 || activeUserType === 2 ? 1 : 2,
      };

      await this.$store.dispatch("participantsStore/addParticipant", data);

      this.view = this.section;
      this.id_user = activeUser.id;
      this.id_project = activeProject.id;
      this.type = type;

      this.show = true;

      if (this.$refs.entryForm) {
        this.$refs.entryForm.resetValidation();
      }
    },

    cancelEntry() {
      this.show = false;
      this.tab = "tab-1";
    },

    async saveEntry() {
      const supervisorsSelected =
        this.$store.getters["participantsStore/getParticipantsSelected"].length > 0;

      if (this.$refs.entryForm.validate() && supervisorsSelected) {
        if (this.support_acknowledgment === "Yes") {
          this.support_ack_description = "";
        }
        if (this.open_access !== "Partial") {
          this.open_access_other = "";
        }
        if (this.identifier === "DOI code") {
          this.other_identifier = "";
        }
        if (this.flag_status === 1) {
          this.estimated_month = "";
          this.estimated_year = "";
        }
        if (this.flag_status !== 1) {
          this.month = "";
          this.year = "";
          this.citedMonth = "";
          this.citedYear = "";
          this.edition = "";
          this.volume = "";
          this.issue = "";
          this.page = "";
        }
        if (this.type === "Journal Article Electronic Only") {
          this.month = "";
          this.year = "";
          this.page = "";
        }
        if (this.type !== "Journal Article Electronic Only") {
          this.citedMonth = "";
          this.citedYear = "";
        }
        if (
          this.type !== "Journal Article Print" &&
          this.type !== "Journal Article Electronic Only"
        ) {
          this.url = "";
        }
        if (this.type === "Book" || this.type === "Book Chapter") {
          this.month = "";
        }
        if (this.type !== "Book" && this.type !== "Book Chapter") {
          this.editors = "";
          this.publisher = "";
          this.publisher_location = "";
          this.book_edition = "";
        }
        if (this.type !== "Book Chapter") {
          this.eLocationID = "";
          this.book_title = "";
        }
        if (this.type !== "Conference Proceeding") {
          this.conferenceName = "";
          this.conferenceLocation = "";
          this.cityStateCountry = "";
        }

        let participants_selected = this.$store.getters[
          "participantsStore/getParticipantsSelected"
        ];
        let non_t2_participants = this.$store.getters[
          "participantsStore/getNonT2"
        ];

        this.participants_selected = participants_selected;
        this.non_t2 = non_t2_participants;

        let entry = this.$store.getters["publicationsStore/getEntry"];

        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("publicationsStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("publicationsStore/update", entry);
        }
        this.cancelEntry();

        /*If saving from the dashboard*/
        if (this.section === 1) {
          const activeUser = this.$store.getters["usersStore/getActiveUser"];
          /* Update users list that shows totals */
          let filters = {
            id_project: this.id_project,
            activeUser: activeUser.id,
            trainee_flag_user_type: 3,
            sectionStored: 9,
            page: 1,
          };
          await this.$store.dispatch("usersStore/fetch", filters);
        }
      } else this.tab = "tab-1";
    },

    nextTab() {
      var tabNum = Number.parseInt(this.tab.substr(4, 1), 10);

      //if (tabNum === 1 && !this.$refs.entryForm.validate()) return;

      tabNum++;

      this.tab = `tab-${tabNum}`;
    },

    previousTab() {
      var tabNum = Number.parseInt(this.tab.substr(4, 1), 10);
      tabNum--;

      this.tab = `tab-${tabNum}`;
    },
    setFedAck(support_acknowledgment) {
      if (support_acknowledgment === "Yes") {
        this.acknowledgment_fed_support = "Yes";
        this.support_ack_description = "";
      } else if (support_acknowledgment === "No") {
        this.acknowledgment_fed_support = "";
      }
    },
    async lookupDOI(doi) {
      this.acknowledgments = "";
      this.title = "";
      this.authors = "";
      this.book_title = "";
      this.journal = "";
      this.book_edition = "";
      this.publisher = "";
      this.url = "";
      this.editors = "";
      this.month = "";
      this.year = "";
      this.volume = "";
      this.issue = "";
      this.page = "";
      this.citedMonth = "";
      this.citedYear = "";
      this.conferenceName = "";
      try {
        let response = await axios.get(doiURL + doi);
        if (response.data.message.author) {
          this.authors = "";
          for (var i = 0; i < response.data.message.author.length; i++) {
            var lname = response.data.message.author[i].family;
            var fname = response.data.message.author[i].given;
            var fullname = lname + ", " + fname;
            if (this.authors) {
              this.authors += ", " + fullname;
            } else {
              this.authors = fullname;
            }
          }
        }
        if (response.data.message.funder) {
          this.acknowledgments = "";
          for (var i = 0; i < response.data.message.funder.length; i++) {
            var name = response.data.message.funder[i].name;

            if (this.acknowledgments) {
              this.acknowledgments += ", " + name;
            } else {
              this.acknowledgments = name;
            }
          }
        }
        /*Journal Article*/
        if (
          response.data.message.type === "journal-article" ||
          response.data.message.type === "reference-type"
        ) {
          if (response.data.message.title) {
            this.title = response.data.message.title[0];
          }
          this.url = response.data.message.URL ? response.data.message.URL : "";
          this.issue = response.data.message.issue;
          this.page = response.data.message.page;
          this.volume = response.data.message.volume;
          if (response.data.message["container-title"]) {
            this.journal = response.data.message["container-title"][0];
          }

          if (
            response.data.message["published-print"] &&
            response.data.message["published-print"]["date-parts"]
          ) {
            var year =
              response.data.message["published-print"]["date-parts"][0][0];
            this.year = year;
            var month =
              response.data.message["published-print"]["date-parts"][0][1];
            var monthName = this.$_monthNumToTextShort(month);
            this.month = monthName;
          }

          if (
            response.data.message["published-online"] &&
            response.data.message["published-online"]["date-parts"]
          ) {
            var year =
              response.data.message["published-online"]["date-parts"][0][0];
            this.citedYear = year;

            var month =
              response.data.message["published-online"]["date-parts"][0][1];
            var monthName = this.$_monthNumToTextShort(month);
            this.citedMonth = monthName;
          }
        } else if (response.data.message.type === "proceedings-article") {
        /*Conference Proceedings*/
          this.title = response.data.message.title[0];
          if (response.data.message["container-title"]) {
            this.journal = response.data.message["container-title"][0];
          }
          this.page = response.data.message.page;
          this.volume = response.data.message.volume;
          if (
            response.data.message["published-print"] &&
            response.data.message["published-print"]["date-parts"]
          ) {
            var year =
              response.data.message["published-print"]["date-parts"][0][0];
            this.year = year;
            var month =
              response.data.message["published-print"]["date-parts"][0][1];
            var monthName = this.$_monthNumToTextShort(month);
            this.month = monthName;
          }
          this.conferenceName = response.data.message.event.name;
        } else if (response.data.message.type === "book-chapter") {
        /*Book Chapter*/
          if (response.data.message.title) {
            this.title = response.data.message.title[0];
          }
          if (response.data.message["container-title"]) {
            this.book_title = response.data.message["container-title"][0];
          }
          this.publisher = response.data.message.publisher;
          if (
            response.data.message["published-print"] &&
            response.data.message["published-print"]["date-parts"]
          ) {
            var year =
              response.data.message["published-print"]["date-parts"][0][0];
            this.year = year;
          }
          this.volume = response.data.message.volume;
          this.page = response.data.message.page;
        } else if (response.data.message.type === "book") {
        /*Book*/
          if (response.data.message.title) {
            this.title = response.data.message.title[0];
          }
          this.publisher = response.data.message.publisher;
          if (
            response.data.message["published-print"] &&
            response.data.message["published-print"]["date-parts"]
          ) {
            var year =
              response.data.message["published-print"]["date-parts"][0][0];
            this.year = year;
          }
          this.volume = response.data.message.volume;
        }
      } catch (error) {
        /*Show message*/
        let message = {
          color: "error",
          text: "DOI Lookup not found.",
        };
        this.$store.commit("messagesStore/setMessage", message);
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
